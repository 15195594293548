import Player from '@vimeo/player';

document.addEventListener(
	'DOMContentLoaded',
	function () {
		const front_page_banner__slick = document.querySelector('.front-page-banner__slick');
		if (front_page_banner__slick) {
			$(front_page_banner__slick).slick({
				arrows: false,
				infinite: false,
				dots: true,
				rows: 0,
				slide: '.front-page-banner-item',
				autoplay: true,
				autoplaySpeed: 5000,
				pauseOnHover: true,
			});
		}
	},
	false
);

const vimeoTriggers = document.querySelectorAll('.vimeo-trigger');
if (vimeoTriggers.length) {
	// Loop through each trigger and add click event listener
	vimeoTriggers.forEach((trigger) => {
		trigger.addEventListener('click', function () {
			// Get the Vimeo ID from the data attribute
			const vimeoId = this.dataset.vimeo;
			const parent = this.parentElement;
			parent.classList.add('vimeo-active');
			// Create a new Vimeo player instance
			const player = new Player('slide-vimeo-' + vimeoId, {
				id: vimeoId,
				title: false,
				byline: false,
				responsive: true,
				autoplay: true,
			});

			player.on('play', function () {
				$front_page_banner__slick.slick('slickPause');
			});

			player.on('ended', function () {
				$front_page_banner__slick.slick('slickPlay');
			});
		});
	});
}

//Cloud Animation

//Declare variables
let velocity;
let lastScrollTop = 0;
//Get element
let elem = $('#service-guarantees .mask');

//On scroll
$(window).on('scroll', function () {
	//get the current scroll position
	let scrollTop = $(window).scrollTop();

	//split background position x and y into array
	var bgPos = elem.css('backgroundPosition').split(' ');

	// detect scroll up or down
	if (scrollTop > lastScrollTop) {
		//down
		velocity = 5;
	} else {
		//up
		velocity = -5;
	}

	//calculate new position in px
	var newBgPosX = parseFloat(bgPos[0]) + velocity + 'px';

	//set background position
	elem.css('backgroundPosition', newBgPosX + ' ' + bgPos[1]);

	//set scroll position for comparision on next scroll event
	lastScrollTop = scrollTop;
});

//Smart Solutions Slider
const smart_slider = $('#smart-solutions__slick');

smart_slider.slick({
	arrows: true,
	dots: false,
	infinite: false,
	slidesToShow: 3,
	slidesToScroll: 1,
	centerMode: false,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				arrows: true,
				centerMode: false,
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 900,
			settings: {
				arrows: true,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 480,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '50px',
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
});
